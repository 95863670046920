body {
  background-size: 100% 180px !important;
}
.card {
  &.card-custom {
    min-height: 70vh;
    background-color: #fff;
  }
}
.cards-main {
  .row {
    flex-wrap: wrap;
  }
  .card {
    // border: 0;
    // background-color: transparent;
    .card-header {
      justify-content: center;
      border-bottom: 0;
      .nav-line-tabs {
        .nav-item {
          align-items: center;
          .nav-link {
            width: 12px;
            height: 12px;
            background-color: #eee;
            border-radius: 100%;
            padding: 0;
            border: 1px solid #eee;
            margin: 0 0.5rem;
            &.active {
              background-color: transparent;
            }
          }
        }
      }
    }
    .tab-pane2 {
      // .col {
      //   &:first-child,
      //   &:nth-child(1n + 2) {
      //     .icon {
      //       background-color: #0193cd;
      //     }
      //   }
      //   &:nth-child(2n + 1) {
      //     .icon {
      //       background-color: #ea4c89;
      //     }
      //   }
      //   &:nth-child(3n + 1) {
      //     .icon {
      //       background-color: #007ee5;
      //     }
      //   }
      //   &:nth-child(4n + 1) {
      //     .icon {
      //       background-color: #0173ba;
      //     }
      //   }
      //   &:nth-child(4n + 1) {
      //     .icon {
      //       background-color: #df4523;
      //     }
      //   }
      //   &:nth-child(5n + 2) {
      //     .icon {
      //       background-color: #35c7ee;
      //     }
      //   }
      //   &:nth-child(6) {
      //     .icon {
      //       background-color: #dd436f;
      //     }
      //   }
      // }
      .card-item {
        box-shadow: none;
        background-color: transparent;
        .icon {
          // height: 63px;
          // width: 63px;
          display: flex;
          align-items: center;
          justify-content: center;
          // background-color: #eee;
          // border: 1px solid #ddd;
          border-radius: 14px;
          img {
            // width: 63px;
            filter: drop-shadow(5px 5px 10px rgba(7, 7, 7, 0.24));
          }
        }
        &:hover {
          // background-color: transparent;
          // .icon {
          // background-color: transparent;
          // box-shadow: 0px 1px 6px 1px rgb(0 75 135 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%),
          // 0px 1px 3px 0px rgb(0 0 0 / 12%);
          // }
          .title {
            h6 {
              color: #004b87;
            }
          }
        }
        .title {
          h6 {
            font-size: 12px;
            text-align: center;
          }
        }
      }
    }
    // .tab-pane3 {
    //   .card-item {
    //     box-shadow: none;
    //     background-color: transparent;
    //     .icon {
    //       height: 63px;
    //       width: 63px;
    //       display: flex;
    //       align-items: center;
    //       justify-content: center;
    //       background-color: #eee;
    //       box-shadow: 0px 1px 6px 1px rgb(0 75 135 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%),
    //         0px 1px 3px 0px rgb(0 0 0 / 12%);
    //       border-radius: 14px;
    //       img {
    //         width: 35px;
    //       }
    //     }
    //     &:hover {
    //       background-color: transparent;
    //       .icon {
    //         background-color: transparent;
    //         box-shadow: 0px 1px 6px 1px rgb(0 75 135 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%),
    //           0px 1px 3px 0px rgb(0 0 0 / 12%);
    //       }
    //       .title {
    //         h6 {
    //           color: #004b87;
    //         }
    //       }
    //     }
    //     .title {
    //       h6 {
    //         font-size: 14px;
    //       }
    //     }
    //   }
    // }
  }
  .card-item {
    // box-shadow: 0px 1px 6px 1px rgb(0 75 135 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%),
    //   0px 1px 3px 0px rgb(0 0 0 / 12%);
    // background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 130px;
    justify-content: center;
    text-decoration: none;
    // .icon {
    //   img {
    //     width: 40px;
    //   }
    // }
    // &:hover {
    //   background-color: #eee;
    // }
    .title {
      h6 {
        font-size: 16px;
        font-weight: 600;
        font-family: 'Poppins', 'Helvetica', 'sans-serif';
        margin-top: 7px;
      }
    }
  }
}
.ag-theme-material .ag-body-horizontal-scroll,
.ag-theme-material .ag-body-horizontal-scroll-viewport .ag-body-horizontal-scroll-container,
.ag-theme-material .ag-body-horizontal-scroll-viewport {
  height: 8px !important;
  max-height: 8px !important;
  min-height: 8px !important;
}
@media (min-width: 992px) {
  .ag-theme-material span::-webkit-scrollbar,
  .ag-theme-material ol::-webkit-scrollbar,
  .ag-theme-material ul::-webkit-scrollbar,
  .ag-theme-material pre::-webkit-scrollbar,
  .ag-theme-material div::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }
}
// .footer {
//   position: fixed;
//   bottom: 15px;
//   width: 100%;
//   z-index: 10;
// }
.login-logo {
  color: #004b87 !important;
  font-size: 28px;
  font-weight: 700;
}
