.expiry-bg {
  background-color: rgba(255, 0, 0, 0.8);
  color: #fff;
}

.react-table-wrapper {
  font-family: 'Poppins';
  font-size: 12px;
}
.tableHover:hover {
  background-color: #ebebeb;
}

.ag-theme-material {
  font-family: 'Poppins';
  font-size: 12px;
  & > div:not(.ag-menu) {
    width: 100%;
    // overflow-x: scroll;
  }
  .ag-header-viewport,
  .ag-floating-top-viewport,
  .ag-body-viewport,
  .ag-center-cols-viewport,
  .ag-floating-bottom-viewport,
  .ag-body-horizontal-scroll-viewport,
  .ag-virtual-list-viewport {
    flex: initial;
  }
  .ag-header {
    width: fit-content;
  }
  .ag-header-row {
    width: 100% !important;
    height: 34px !important;
    font-weight: 600;
    font-family: 'Poppins';
    color: #000;
    overflow: visible;
  }
  .ag-row {
    height: 42px !important;
    .btn.btn-icon.btn-bg-light.btn-active-color-primary.btn-sm {
      margin-top: 1px;
    }
  }
  .ag-header-cell {
    padding-left: 0 !important;
    min-width: 150px !important;
    word-break: break-word;
    height: 34px !important;
    padding-right: 20px;
  }
  .ag-floating-filter-input {
    .ag-input-field-input {
      border-bottom: 0 !important;
    }
  }
  .ag-header-group-cell {
    padding-left: 0 !important;
    padding-right: 0 !important;
    word-break: break-word;
    height: 34px !important;
  }
  .ag-cell {
    word-break: break-word;
    padding-left: 0;
    padding-right: 20px;
    line-height: 39px;
    .badge {
      display: flex;
      align-items: center;
      font-size: 10px;
    }
  }
  .ag-full-width-row {
    .ag-cell-wrapper {
      &.ag-row-group {
        word-break: break-word;
        height: 34px !important;
        line-height: 32px !important;
        padding-left: 0;
        padding-right: 0;
      }
    }
  }
  .ag-ltr {
    .ag-floating-filter-button {
      margin-left: -20px;
    }
  }
  input {
    &[class^='ag-']:not([type]),
    &[class^='ag-'][type='text'],
    &[class^='ag-'][type='number'],
    &[class^='ag-'][type='tel'],
    &[class^='ag-'][type='date'],
    &[class^='ag-'][type='datetime-local'] {
      height: 34px !important;
      line-height: 32px !important;
      padding-bottom: 0 !important;
    }
  }
  textarea[class^='ag-'],
  .ag-cell-inline-editing {
    height: 34px !important;
    line-height: 32px !important;
    padding-bottom: 0 !important;
  }
  .ag-paging-panel {
    border-top: 0;
    justify-content: flex-start;
    flex-wrap: wrap;
    .ag-paging-row-summary-panel {
      margin-left: 0 !important;
    }
  }
}
.ag-header-cell-menu-button {
  &:not(.ag-header-menu-always-show) {
    opacity: 1;
  }
}
.ag-theme-material .ag-ltr .ag-has-focus .ag-cell-focus:not(.ag-cell-range-selected),
.ag-theme-material .ag-ltr .ag-context-menu-open .ag-cell-focus:not(.ag-cell-range-selected) {
  border-color: transparent !important;
}
.whiteSpacesClass {
  white-space: nowrap;
}
.form-select,
.form-control {
  box-shadow: none !important;
  border-color: #cccccc !important;
  border: 1px solid #cccccc !important;

  max-height: 38px !important;
  min-height: 38px !important;
  line-height: 1.2 !important;
  border-radius: 4px !important;
}
.form-select {
  color: #808080 !important;
  margin-left: 2px;
  margin-right: 2px;
  box-sizing: border-box;
  font-family: 'Poppins', Helvetica, 'sans-serif';
  font-weight: 400 !important;
  font-size: 13px !important;
  text-transform: capitalize;
  padding: 0.75rem 3rem 0.75rem 1rem !important;
}
.form-control {
  color: #808080 !important;
  margin-left: 2px;
  margin-right: 2px;
  box-sizing: border-box;
  font-family: Poppins, Helvetica, 'sans-serif';
  font-weight: 400 !important;
  font-size: 13px !important;
  // text-transform: capitalize;
  padding: 0.75rem 1rem 0.75rem 1rem !important;
  &::placeholder {
    color: #808080 !important;
    font-family: Poppins, Helvetica, 'sans-serif';
    font-weight: 400 !important;
    font-size: 13px !important;
    text-transform: capitalize;
  }
}
input[type='date'] {
  padding: 0.75rem 1rem !important;
}
// #kt_modal_create_app_form .col {
//     min-width: 130px;
// }
// #kt_modal_create_app_form .row {
//     overflow: auto;
// }
// .table.table-responsivness tbody tr td, .table.table-responsivness tbody tr:last-child td
table {
  table-layout: fixed;
  &.table {
    &.tbl-border {
      thead {
        tr {
          th {
            border-top: 1px solid #333 !important;
            border-bottom: 1px solid #333 !important;
            border-left: 1px solid #333 !important;
            border-right: 1px solid #333 !important;
            padding: 0.75rem 0.75rem;
          }
        }
      }
      tbody {
        tr {
          td {
            border-top: 1px solid #333 !important;
            border-bottom: 1px solid #333 !important;
            border-left: 1px solid #333 !important;
            border-right: 1px solid #333 !important;
            padding: 0.75rem 0.75rem;
          }
          .expiry-bg {
            color: rgba(255, 0, 0, 0.8);
            font-weight: bold;
          }
        }
      }
    }
  }
}
.rs-picker-toggle-value {
  color: #808080 !important;
}
.action-btn-wrapper {
  button {
    height: 20px !important;
  }
  a {
    height: 20px !important;
  }
}

.inventory-report-svg-icon {
  height: 2.3rem !important;
  width: 2.3rem !important;
}

.expiry-date {
  color: rgba(255, 0, 0, 0.8);
  font-weight: bold;
}

@media (max-width: 1920px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: 1800px !important;
  }
}
@media (max-width: 1800px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: 1720px !important;
  }
}
@media (max-width: 1600px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: 1520px !important;
  }
}
@media not print {
  @media (max-width: 1399.98px) {
    table {
      &.tbl-xxl-responsivness {
        &.table-full-td {
          tbody {
            tr {
              td {
                &::before {
                  display: none;
                }
              }
            }
          }
        }
        thead {
          display: none;
        }
        tbody {
          tr {
            display: flex;
            flex-wrap: wrap;
            margin-bottom: 1.25rem;
            &:not(:last-child) {
              border-bottom: 1px solid rgb(221, 221, 221) !important;
            }
            td {
              border-bottom: 0px solid #ddd;
              align-items: center;
              min-width: 50%;
              width: 50% !important;
              font-size: 13px;
              display: inline-flex;
              padding: 1rem 0.75rem 1rem 0.75rem !important;
              .multi-select-container {
                width: 100%;
              }
              &:first-child {
                padding: 1rem 0.75rem 1rem 0.75rem !important;
              }
              // span {
              //   font-size: 13px;
              //   width: 100% !important;
              //   min-width: 100% !important;
              // }
              &::before {
                content: attr(data-label);
                float: left;
                font-weight: 600;
                text-transform: uppercase;
                min-width: 45%;
                max-width: 45%;
                text-align: left;
                font-size: 12px;
                text-transform: capitalize;
              }
              &.dl {
                display: none !important;
              }
            }
          }
        }
      }
    }
  }
}
@media not print {
  @media (max-width: 1199.98px) {
    table {
      &.tbl-xl-responsivness {
        &.table-full-td {
          tbody {
            tr {
              td {
                &::before {
                  display: none;
                }
              }
            }
          }
        }
        thead {
          display: none;
        }
        tbody {
          tr {
            display: flex;
            flex-wrap: wrap;
            margin-bottom: 1.25rem;
            &:not(:last-child) {
              border-bottom: 1px solid rgb(221, 221, 221) !important;
            }
            td {
              border-bottom: 0px solid #ddd;
              align-items: center;
              min-width: 50%;
              width: 50% !important;
              font-size: 13px;
              display: inline-flex;
              padding: 1rem 0.75rem 1rem 0.75rem !important;
              .multi-select-container {
                width: 100%;
              }
              &:first-child {
                padding: 1rem 0.75rem 1rem 0.75rem !important;
              }
              // span {
              //   font-size: 13px;
              //   width: 100% !important;
              //   min-width: 100% !important;
              // }
              &::before {
                content: attr(data-label);
                float: left;
                font-weight: 600;
                text-transform: uppercase;
                min-width: 45%;
                max-width: 45%;
                text-align: left;
                font-size: 12px;
                text-transform: capitalize;
              }
              &.dl {
                display: none !important;
              }
            }
          }
        }
      }
    }
  }
}
@media not print {
  @media (max-width: 991px) {
    .ag-root-wrapper {
      &.ag-layout-normal {
        height: 100%;
        width: 100% !important;
        min-width: fit-content !important;
      }
    }
    // #kt_modal_create_app_form .col {
    //     min-width: 50%;
    //     width: 50%;
    //     margin-bottom: 15px;
    // }
    // #kt_modal_create_app_form label.col-form-label,
    // #kt_modal_create_app_form label.col-form-label + .col {
    //     min-width: 100%;
    //     width: 100%;
    //     margin-bottom: 0;
    // }
    table {
      &.tbl-lg-responsivness {
        &.table-full-td {
          tbody {
            tr {
              td {
                &::before {
                  display: none;
                }
              }
            }
          }
        }
        thead {
          display: none;
        }
        tbody {
          tr {
            display: flex;
            flex-wrap: wrap;
            &:not(:last-child) {
              border-bottom: 1px solid rgb(221, 221, 221) !important;
            }
            td {
              border-bottom: 0px solid #ddd;
              align-items: center;
              min-width: 100%;
              width: 100% !important;
              font-size: 13px;
              display: inline-flex;
              padding: 1rem 0.75rem 1rem 0.75rem !important;
              .multi-select-container {
                width: 100%;
              }
              &:first-child {
                padding: 1rem 0.75rem 1rem 0.75rem !important;
              }
              // span {
              //   font-size: 13px;
              //   width: 100% !important;
              //   min-width: 100% !important;
              // }
              &::before {
                content: attr(data-label);
                float: left;
                font-weight: 600;
                text-transform: uppercase;
                min-width: 40%;
                max-width: 40%;
                text-align: left;
                font-size: 12px;
                text-transform: capitalize;
              }
              &.dl {
                display: none !important;
              }
            }
          }
        }
      }
      &.tbl-responsivness-noLabel {
        tbody {
          tr {
            &:not(:last-child) {
              margin-bottom: 5px !important;
            }
            td {
              &::before {
                display: none;
              }
            }
          }
        }
      }
      &.tbl-xl-responsivness {
        tbody {
          tr {
            td {
              border-bottom: 0px solid #ddd;
              align-items: center;
              min-width: 100%;
              width: 100% !important;
              &:before {
                min-width: 40%;
                max-width: 40%;
              }
            }
          }
        }
      }
    }
  }
}
@media not print {
  @media (max-width: 767.98px) {
    table {
      &.tbl-md-responsivness {
        &.table-full-td {
          tbody {
            tr {
              td {
                &::before {
                  display: none;
                }
              }
            }
          }
        }
        thead {
          display: none;
        }
        tbody {
          tr {
            display: flex;
            flex-wrap: wrap;
            margin-bottom: 1.25rem;
            &:not(:last-child) {
              border-bottom: 1px solid rgb(221, 221, 221) !important;
            }
            td {
              border-bottom: 0px solid #ddd;
              align-items: center;
              min-width: 100%;
              width: 100% !important;
              font-size: 13px;
              display: inline-flex;
              padding: 1rem 0.75rem 1rem 0.75rem !important;
              .multi-select-container {
                width: 100%;
              }
              &:first-child {
                padding: 1rem 0.75rem 1rem 0.75rem !important;
              }
              // span {
              //   font-size: 13px;
              //   width: 100% !important;
              //   min-width: 100% !important;
              // }
              &::before {
                content: attr(data-label);
                float: left;
                font-weight: 600;
                text-transform: uppercase;
                min-width: 45%;
                max-width: 45%;
                text-align: left;
                font-size: 12px;
                text-transform: capitalize;
              }
              &.dl {
                display: none !important;
              }
            }
          }
        }
      }
      &.tbl-xxl-responsivness,
      &.tbl-lg-responsivness,
      &.tbl-xl-responsivness {
        tbody {
          tr {
            td {
              border-bottom: 0px solid #ddd;
              align-items: center;
              min-width: 100%;
              width: 100% !important;
              &:before {
                min-width: 45%;
                max-width: 45%;
              }
            }
          }
        }
      }
    }
  }
}
@media not print {
  @media (max-width: 575px) {
    .ag-theme-material {
      .ag-paging-panel {
        flex-direction: column;
        align-items: flex-start;
        & > * {
          margin-left: 0 !important;
        }
        .ag-paging-page-summary-panel {
          margin-top: 7px;
          .ag-paging-button {
            &:first-child {
              margin-left: -3px !important;
            }
          }
        }
      }
    }

    table {
      &.tbl-sm-responsivness {
        &.table-full-td {
          tbody {
            tr {
              td {
                &::before {
                  display: none;
                }
              }
            }
          }
        }
        thead {
          display: none;
        }
        tbody {
          tr {
            display: flex;
            flex-wrap: wrap;
            margin-bottom: 1.25rem;
            &:not(:last-child) {
              border-bottom: 1px solid rgb(221, 221, 221) !important;
            }
            td {
              border-bottom: 0px solid #ddd;
              align-items: center;
              min-width: 100%;
              width: 100% !important;
              font-size: 13px;
              display: inline-flex;
              padding-top: 0 !important;
              padding-left: 0 !important;
              padding: 1rem 0.75rem 1rem 0.75rem !important;
              .multi-select-container {
                width: 100%;
              }
              &:first-child {
                padding: 1rem 0.75rem 1rem 0.75rem !important;
              }
              // span {
              //   font-size: 13px;
              //   width: 100% !important;
              //   min-width: 100% !important;
              // }
              &::before {
                content: attr(data-label);
                float: left;
                font-weight: 600;
                text-transform: uppercase;
                min-width: 45%;
                max-width: 45%;
                text-align: left;
                font-size: 12px;
                text-transform: capitalize;
              }
              &.dl {
                display: none !important;
              }
            }
          }
        }
      }
    }
  }
}

@media not print {
  @media (max-width: 475px) {
    table {
      &.tbl-sm-responsivness,
      &.tbl-md-responsivness,
      &.tbl-lg-responsivness,
      &.tbl-xl-responsivness,
      &.tbl-xxl-responsivness {
        tbody {
          tr {
            margin-bottom: 1.25rem;
            td {
              border-bottom: 0px solid #ddd;
              align-items: center;
              min-width: 100%;
              width: 100% !important;
              flex-direction: column;
              padding: 0 0 1.25rem 0 !important;
              h5 {
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: 100%;
                margin-top: 15px;
              }
              &:first-child {
                padding: 0 0 1.25rem 0 !important;
              }
              &:before {
                min-width: 100%;
                max-width: 100%;
              }
            }
          }
        }
      }
    }
  }
}
svg {
  pointer-events: none;
}
.print-view-show {
  display: none;
}
@media print {
  .print-view-none {
    display: none !important;
  }
  .print-view-show {
    display: block;
    position: relative;
    top: 21px;
    text-align: center;
    height: 100px;
    margin: 0 auto;
  }
}

.bar-chart-container {
  width: 100%;
  max-width: 1000px; /* adjust the maximum width as needed */
  height: 500px;
  margin: 0 auto;
}
@media (max-width: 768px) {
  .bar-chart-container {
    height: 300px;
    max-width: 750px; /* adjust the maximum width as needed */
  }
}

.pie-chart-container {
  width: 100%;
  max-width: 500px; /* adjust the maximum width as needed */
  height: auto;
  margin: 0 auto;
}
@media (max-width: 768px) {
  .pie-chart-container {
    max-width: 350px; /* adjust the maximum width as needed */
  }
}

.doughnut-chart-container {
  width: 100%;
  max-width: 500px; /* adjust the maximum width as needed */
  height: auto;
  margin: 0 auto;
}
@media (max-width: 768px) {
  .doughnut-chart-container {
    max-width: 400px; /* adjust the maximum width as needed */
  }
}

.custom-grid {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  @media screen and (max-width: 1200px) {
    grid-template-columns: repeat(6, 1fr);
  }
  @media screen and (max-width: 991px) {
    grid-template-columns: repeat(5, 1fr);
  }
  @media screen and (max-width: 767px) {
    grid-template-columns: repeat(4, 1fr);
  }
  @media screen and (max-width: 575px) {
    grid-template-columns: repeat(2, 1fr);
  }
}

.btn-group .btn.active {
  background-color: #c0c8f3;
}

@media screen and (max-width: 418px) {
  .report-row {
    display: flex;
    flex-wrap: wrap;
  }
  .report-btn {
    margin-top: 10px;
  }
}
