@media print {
  .mx-10 {
    display: none !important;
  }
  #kt_header_user_menu_toggle {
    display: none !important;
  }
  .header {
    display: none !important;
  }
  .scrolltop {
    display: none !important;
  }

  @page {
    size: A4 portrait;
    margin: 0.1cm;
  }
  a[title='Print'] {
    display: none;
  }
  .download-btn {
    display: none;
  }
  .print {
    // background-image: url('https://nextbridge.com/wp-content/uploads/2022/02/Group-187.svg') !important;
    // background-repeat: no-repeat !important;
    // background-position: top !important;
    // // background-size: 130px 100px !important;
    // top: 0px !important;
    // padding-top: 120px !important;
    // z-index: 9999;
    .form-control {
      background: transparent !important;
      border: none !important;
    }
    .form-group {
      .form-control {
        padding: 0 !important;
      }
    }
    .print-table {
      .mt-* {
        margin: 0 !important;
      }
      border-top: 1px solid black !important;
      border-left: 1px solid black !important;
      margin: 0 !important;
      .print-table-header {
        margin: 0 !important;
        .print-table-cell {
          margin: 0 !important;
          border-bottom: 1px solid black !important;
          border-right: 1px solid black !important;
        }
      }
      .print-table-row {
        margin: 0 !important;
        .print-table-cell {
          margin: 0 !important;
          border-bottom: 1px solid black !important;
          border-right: 1px solid black !important;
        }
      }
    }
    button {
      display: none !important;
    }
  }
  * {
    -webkit-print-color-adjust: exact !important; /*Chrome, Safari */
    print-color-adjust: exact !important; /*Firefox*/
  }
}
