.stepper-1 {
  &.stepper {
    &.stepper-links {
      .stepper-nav {
        border-bottom: 1px solid #ebedf3;
        .stepper-item {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          text-align: center;
          margin: 1rem;
          .stepper-icon {
            font-size: 28px;
            height: 55px;
            fill: #b7b7b7;
          }
          .stepper-title {
            color: #7e8299;
            font-size: 13px;
            font-weight: 500;
            margin-top: 8px;
          }
          &:after {
            display: none !important;
          }
          &:hover,
          &.current {
            color: #004b87;
            cursor: pointer;
            .stepper-icon {
              rect,
              path {
                fill: #004b87;
              }
            }
            .stepper-arrow {
              svg {
                fill: #004b87;
                rect,
                path {
                  fill: #004b87;
                }
              }
            }
            .stepper-icon,
            .stepper-title,
            .stepper-label {
              color: #004b87;
            }
            &:after {
              display: none !important;
            }
          }
          .stepper-label {
            width: 90px;
            min-height: 76px;
          }

          &:not(:last-child) {
            .stepper-label {
              margin-right: 1rem;
            }
          }
          &:last-child {
            .stepper-arrow {
              display: none;
            }
          }
          .stepper-icon {
            &:hover {
              fill: #004b87;
              svg,
              g {
                fill: #004b87;
                opacity: 1;
              }
            }
          }
          .stepper-arrow {
            svg {
              -webkit-transition: fill 0.3s ease;
              transition: fill 0.3s ease;
              fill: #b5b5c3;
              opacity: 0.8;
              rect,
              path {
                fill: #b5b5c3;
              }
              &:hover {
                fill: #004b87;
                rect,
                path {
                  fill: #004b87;
                  opacity: 1;
                }
              }
            }
            i {
              font-size: 14px;
            }
          }
        }
      }
    }
  }
}
@media (max-width: 767.98px) {
  .stepper-1 {
    &.stepper {
      &.stepper-links {
        .stepper-nav {
          width: 100%;
          justify-content: space-between;
          .stepper-item {
            width: 48%;
            margin: 1rem 0;
            justify-content: space-between;
            .stepper-label {
              display: flex;
              flex-direction: row;
              align-items: center;
              min-height: auto;
              margin-right: 0;
              width: auto;
              .stepper-icon {
                margin-right: 1rem;
              }
            }
          }
        }
      }
    }
  }
}
@media (max-width: 575.98px) {
  .stepper-1 {
    &.stepper {
      &.stepper-links {
        .stepper-nav {
          flex-direction: column;
          width: 100%;
          .stepper-item {
            width: 100%;
            .stepper-label {
              margin-right: 0;
            }
          }
        }
      }
    }
  }
}
