.legend-body {
  display: inline-flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  & > div:not(:last-child) {
    margin-right: 15px;
  }
  h3 {
    display: inline-flex;
    align-items: center;
    padding-right: 15px;
    text-transform: uppercase;
    font-size: 13.35px;
    color: #a1a5b7;
  }
  .periority-not-approved {
    .progress-bar {
      width: 100px;
    }
  }
  .periority-approved {
    .progress-bar {
      width: 100px;
    }
  }
  .periority-rejected {
    .progress-bar {
      width: 100px;
    }
  }
}
@media (max-width: 991.98px) {
  .legend-body {
    padding-left: 0 !important;
    width: 100%;
    justify-content: flex-start;
    order: 3;
  }
}
@media (max-width: 575.98px) {
  .legend-body {
    & > div {
      margin-top: 10px;
      &:not(:last-child) {
        margin-right: 10px;
      }
    }
    h3 {
      width: 100%;
      margin-bottom: 10px;
    }
  }
}
